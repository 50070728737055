<template>
  <div class="content">

    <!-- <div class="info-box">
      基础积分：年轻党员  +30分
    </div> -->
    <div class="list-box">
      <div class="list-item-title">
        <div>积分事项</div>
        <div>分值</div>
        <div>更新时间</div>
      </div>
      <div class="list-item-body" v-for="(item, index) in ruleList" :key="index">
        <div>{{item.title}}</div>
        <div>+{{item.integral}}分</div>
        <div>{{item.updateTime}}</div>
      </div>
    </div>

  </div>
</template>
<script>
import { getScoreRule } from '@/api/myScore';

export default {
  name: 'scoreRule',
  data() {
    return {
      scoreInfo: {
        avatar: "http://b-ssl.duitang.com/uploads/item/201511/21/20151121171107_zMZcy.jpeg",
        monthScore: "5",
        rank: "2",
        score: "123"
      },
      ruleList: []
    };
  },
  created() {
    const { pairingId } = this.$route.query;
    //请求积分规则
    getScoreRule({pairingId}).then(res => {
      console.log(res)
      this.ruleList = res.data
    });
  }
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.content{
  background-color: #f5f5f5;
  .info-box{
    font-size: 28*$px;
    color: #222;
    padding-left: 25*$px;
    height: 88*$px;
    display: flex;
    align-items: center;
    margin-bottom: 20*$px;
    background-color: #ffffff;
  }
  .list-box{
    background: #ffffff;
    .list-item-title{
      display: flex;
      font-size: 28*$px;
      color: #999;
      padding: 22*$px 0 22*$px 25*$px;
      line-height: 36*$px;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      > div:nth-child(1){
        width: 310*$px;
      }
      > div:nth-child(2){
        width: 140*$px;
      }
      > div:nth-child(3){
        width: 260*$px;
      }
    }
    .list-item-body{
      display: flex;
      font-size: 28*$px;
      color: #222;
      padding: 26*$px 0 26*$px 25*$px;
      line-height: 36*$px;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      > div:nth-child(1){
        width: 290*$px;
        margin-right: 20*$px;
      }
      > div:nth-child(2){
        width: 140*$px;
        color: #FF192F;
      }
      > div:nth-child(3){
        width: 260*$px;
      }
    }
  }
}
</style>